import { applyOverrides } from '@tytapp/environment-switcher';
import { mobileOverrides } from '@tytapp/environments/mobile-overrides';
import { version } from '@tytapp/environments/version';

export type Environment = typeof environment;
export const environment = {
    name: 'staging',
    version,
    allowSearchEngines: false,
    production: true,
    angularProductionMode: true,
    showPanics: true,
    enableServiceWorker: true,
    testing: false,
    showDevTools: true,
    showDevNotes: false,
    apiOverride: undefined,
    showEnvironmentHint: true,
    isNativeBuild: false,
    productName: 'TYT.com',
    urls: {
        root: 'https://app.staging.tyt.com',
        webRoot: 'https://app.staging.tyt.com',
        canonicalRoot: 'https://app.staging.tyt.com',
        accounts: 'https://accounts.staging.tyt.com',
        purchase: 'https://accounts.staging.tyt.com/join',
        donate: 'https://secure.actblue.com/donate/tytnetwork',
        platform: 'https://platform.staging.tytnetwork.com',
        cable: 'https://platform.staging.tyt.com/cable',
        api: 'https://platform.staging.tyt.com/api/v1',
        admin: 'https://platform.staging.tyt.com',
        forums: 'https://discuss.staging.tyt.com',
        bantaServer: 'https://banta.staging.tyt.com',
        cdn: 'https://cdn.staging.tytnetwork.com',
        releaseCdn: 'https://d3o2cozotd14f8.cloudfront.net',
        appStoreUrl: 'https://itunes.apple.com/us/app/tyt-plus-news-entertainment/id1319576607?mt=8',
        playStoreUrl: 'https://play.google.com/store/apps/details?id=com.tyt.battlecruiser&hl=en_US'
    },
    auth: {
        domain: '.staging.tyt.com',
        cookie: 'tytauth_staging',
        userCookie: 'tyt_user_uuid_staging',
    },
    cookies: {
        storeInLocalStorage: false
    },
    logging: {
        verbose: true
    },
    serverLogging: {
        verbose: true
    },

    stripeKey: 'pk_adWnCOMJxEWmPRRrETjFe4FAI02CX',
    googleCastAppId: 'FF99FB52',
    recaptchaV2SiteKey: '6LfORrIZAAAAADh6jtBF5aMhuEc3XfLjN4wBwepR',
    recaptchaV3SiteKey: '6LfsRbIZAAAAAMcH0NLIDxSqvJy3JNUs3XpWxQ4L',
    bugsnagKey: '100b690374ca57ef9bc9b16b1dd9c08a',
    vapidPublicKey: 'BExah5pk0Ks_eiRhCP_LlaFcRSvqiPxbCmVCMCY4-K6auyJtNbW6U1svm8QxnFPFEVQKdG0ZfB0nKflCGeZUHH4',
    appleAccountClientId: 'com.tyt.platform',

    firebase: {
        apiKey: "AIzaSyBCFYjNvgT-OKu8Lxio-LrO9RyYm_AWaAg",
        authDomain: "tyt-plus-b5a16.firebaseapp.com",
        databaseURL: "https://tyt-plus-b5a16.firebaseio.com",
        projectId: "tyt-plus-b5a16",
        storageBucket: "tyt-plus-b5a16.appspot.com",
        messagingSenderId: "453075902535",
        appId: "1:453075902535:web:dc2edb959e797a55459162",
        measurementId: "G-Q3C2VEYM6K"
    }
}

mobileOverrides(environment);
applyOverrides(environment);