import type { Environment } from './environment';
import deepMerge from 'deepmerge';
import { isClientSide } from '@tytapp/environment-utils';
type DeepPartial<T> = T extends object ? { [P in keyof T]?: DeepPartial<T[P]>; } : T;

const OVERRIDES: Record<string, DeepPartial<Environment>> = {
    'staging': {
        urls: {
            canonicalRoot: 'https://app.staging.tyt.com',
            api: 'https://platform.staging.tyt.com/api/v1',
            bantaServer: 'https://banta.staging.tyt.com',
            forums: 'https://discuss.staging.tyt.com',
            platform: 'https://platform.staging.tyt.com'
        }
    },
    'tytdev': {
        urls: {
            canonicalRoot: 'https://app.tytdev.docker',
            api: 'https://platform.tytdev.docker/api/v1',
            bantaServer: 'https://banta.tytdev.docker',
            forums: 'https://discuss.tytdev.docker',
            platform: 'https://platform.tytdev.docker'
        }
    },
    'production': {
        urls: {
            canonicalRoot: 'https://tyt.com',
            api: 'https://platform.tyt.com/api/v1',
            bantaServer: 'https://banta.tyt.com',
            forums: 'https://discuss.tyt.com',
            platform: 'https://platform.tyt.com'
        }
    }
}

export function applyOverrides(environment: Environment) {
    if (isClientSide()) {
        const localStorage = globalThis['localStorage'];
        let name = localStorage.getItem('tyt-api-override');

        if (name) {
            Object.assign(environment, deepMerge(environment, OVERRIDES[name]));
            environment.urls.webRoot = environment.urls.canonicalRoot;
            environment.apiOverride = name;
        }

        let native = localStorage['tyt-native-build-override'];
        if (native !== undefined) {
            environment.isNativeBuild = native === '1';
        }

        let productName = localStorage['tyt-product-name-override'];
        if (productName !== undefined) {
            environment.productName = productName;
        }

    }
}
